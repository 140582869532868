<template>
    <div>
        <page-title :heading="$t('erp.lang_nav_warecreate') " show-previous-button :subheading="$t('erp.lang_nav_warecreate')" :icon=icon></page-title>
        <div class="app-main__inner">
        <CreateItem></CreateItem>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import CreateItem from "../../../components/erp/baseData/CreateItem.vue";

    export default {
        components: {
            PageTitle,
            CreateItem
        },
        data: () => ({
            icon: 'pe-7s-plugin icon-gradient bg-tempting-azure',
        })
    }
</script>